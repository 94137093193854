<template>
  <div class="blog__comment">
    <h3>Write a Review</h3>
    <form action="#">
    <div class="row">
        <div class="col-xxl-6 col-xl-6 col-lg-6">
            <div class="blog__comment-input">
                <input type="text" placeholder="Your Name">
            </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6">
            <div class="blog__comment-input">
                <input type="email" placeholder="Your Email">
            </div>
        </div>
        <div class="col-xxl-12">
            <div class="blog__comment-input">
                <input type="text" placeholder="Website">
            </div>
        </div>
        <div class="col-xxl-12">
            <div class="blog__comment-input">
                <textarea placeholder="Enter your comment ..."></textarea>
            </div>
        </div>
        <div class="col-xxl-12">
            <div class="blog__comment-agree d-flex align-items-center mb-20">
                <input class="e-check-input" type="checkbox" id="e-agree">
                <label class="e-check-label" for="e-agree">Save my name, email, and website in this browser for the next time I comment.</label>
            </div>
        </div>
        <div class="col-xxl-12">
            <div class="blog__comment-btn">
                <button type="submit" class="e-btn">Post Comment</button>
            </div>
        </div>
    </div>
  </form>
</div>
</template>

<script>
export default {
    name:'BlogComment'
}
</script>

